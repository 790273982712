import { collection, doc, getFirestore, onSnapshot, query, where, orderBy } from 'firebase/firestore'

const reportsStoreCollection = collection(getFirestore(), 'reports_store')
const reportsStoreDepartmentCollection = collection(getFirestore(), 'reports_store_department')

const reportsBuCollection = collection(getFirestore(), 'reports_bu')
const reportsBuDepartmentCollection = collection(getFirestore(), 'reports_bu_department')

const reportsRegionCollection = collection(getFirestore(), 'reports_region')
const reportsRegionDepartmentCollection = collection(getFirestore(), 'reports_region_department')

/**
 * Find a Reports Kpi for a {@link scope}, a {@link buId}, a {@link storeId}.
 * @param {string} scope e.g. 'bu', 'store', 'region'
 * @param {number} buId e.g. 1
 * @param {number} [id=] e.g. 75
 * @param {function} callbackFn
 * @return {function}
 */
export function findReportAsync (scope, buId, id, callbackFn) {
  let reportsCollection = reportsBuCollection
  let reportsId = `${buId}`

  switch (scope) {
    case 'store':
      reportsCollection = reportsStoreCollection
      reportsId = `${buId}_${id}`
      break
    case 'region':
      reportsCollection = reportsRegionCollection
      reportsId = `${buId}_${id}`
  }

  return onSnapshot(
    doc(reportsCollection, reportsId),
    snapshot => {
      let reportMain = undefined

      if (snapshot.exists()) {
        reportMain = { id: snapshot.id, ...snapshot.data() }
      }
      
      callbackFn(reportMain)
    })
}


/**
 * Find all Department Reports Kpis for a {@link scope}, {@link buId} and a {@link storeId}.
 * @param {string} scope e.g. 'bu', 'store'
 * @param {number} buId e.g. 1
 * @param {number} id e.g. 75
 * @param {function} callbackFn
 * @return {function}
 */
export function findAllDepartmentReportsAsync (scope, buId, id, callbackFn) {
  let reportsDepartmentCollection = reportsBuDepartmentCollection
  
  const whereClauses = [
    where('buId', '==', Number(buId)),
  ]
  
  switch (scope) {
    case 'region':
      reportsDepartmentCollection = reportsRegionDepartmentCollection
      whereClauses.push(where('regionId', '==', Number(id)))
      break
    case 'store':
      reportsDepartmentCollection = reportsStoreDepartmentCollection
      whereClauses.push(where('storeId', '==', Number(id)))
  }

  return onSnapshot(
    query(
      reportsDepartmentCollection,
      ...whereClauses,
      orderBy('departmentId')),
    querySnapshot => {
      const reports = querySnapshot.docs.reduce((acc, documentSnapshot) => {
        acc.push({ id: documentSnapshot.id, ...documentSnapshot.data() })
        return acc
      }, [])
      callbackFn(reports)
    })
}

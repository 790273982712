<template>
  <v-progress-linear class="fr-loader"
                     color="primary"
                     indeterminate />
</template>

<style scoped lang="sass">
.fr-loader
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 9999
</style>

<template>
  <div class="dashboard-comparison-date text-caption">
    <FrIcon class="mr-1" 
            modelValue="info" />
    <span class="text-grey-darken-1"
          v-html="t('flash-report_dashboard_comparisonDate', { timeFromNow, date: displayDate(props.toDate) })" />
  </div>  
</template>

<script setup>
import { defineProps, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import FrIcon from '@/components/FrIcon'

import { displayDate, displayRelativeDate } from '@/services/dateTimeService'

const { t } = useI18n()

const props = defineProps({
  fromDate: {
    type: Date,
  },
  toDate: {
    type: Date,
  }
})

const timeFromNow = ref(undefined)

let timeFromNowPointer

onMounted(() => {
  timeFromNow.value = displayRelativeDate(props.fromDate)

  timeFromNowPointer = setInterval(
    () => timeFromNow.value = displayRelativeDate(props.fromDate),
    1000)
})

onUnmounted(() => {
  clearInterval(timeFromNowPointer)
})
</script>

<style scoped lang="sass">
.dashboard-comparison-date
  display: flex
  align-items: center
</style>
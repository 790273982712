function shouldHaveACode (to, from, next) {
  if (to.query.code) {
    next()
  } else {
    next({ name: 'Default' })
  }
}

export default [
  {
    path: '/authentication',
    component: () => import(/* webpackChunkName: "authentication" */ './views/AuthenticationLayout.vue'),
    children: [
      {
        path: 'callback',
        name: 'AuthenticationCallback',
        meta: {
          title: 'flash-report_authentication_callback_title'
        },
        component: () => import(/* webpackChunkName: "authentication" */ './views/AuthenticationCallback.vue'),
        beforeEnter: shouldHaveACode
      }
    ]
  }
]

import { collection, doc, getFirestore, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore'

import { isAuthenticated, getAuthenticatedUser } from '@/authentication/services/authenticationService'

const usersProfileCollection = collection(getFirestore(), 'users_profile')

/**
 * Find profile for the authenticated user.
 * @return {Promise<*>}
 */
export async function findUserProfile () {
  const isAuthenticatedUser = await isAuthenticated()

  if (!isAuthenticatedUser) {
    return undefined
  }

  const id = getAuthenticatedUser().uid
  const documentSnapshot = await getDoc(doc(usersProfileCollection, id))

  if (documentSnapshot.exists()) {
    return { id: documentSnapshot.id, ...documentSnapshot.data() }
  }

  return undefined
}

/**
 * Create or update profile {@link data} for the authenticated user.
 * @param {object} data
 * @return {Promise<*>}
 */
export async function createOrUpdateUserProfile (data) {
  const id = getAuthenticatedUser().uid
  const documentReference = doc(usersProfileCollection, id)

  const documentSnapshot = await getDoc(documentReference)

  if (documentSnapshot.exists()) {
    return updateDoc(
      documentReference,
      {
        lastModifiedAt: serverTimestamp(),
        ...data
      })
  } else {
    return setDoc(
      documentReference,
      {
        createdAt: serverTimestamp(),
        ...data
      })
  }
}

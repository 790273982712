import { collection, doc, getFirestore, getDoc, getDocs, query, where, orderBy } from 'firebase/firestore'

const storeCollection = collection(getFirestore(), 'stores')

/**
 * Find all stores for a {@link buId}.
 * @param {number} buId 
 * @return {Promise<Array>}
 */
export async function findAllStores (buId) {
  const stores = []
  const querySnapshot = await getDocs(query(storeCollection, where('buId', '==', buId), orderBy('storeId')))

  querySnapshot.forEach((documentSnapshot) => stores.push({ id: documentSnapshot.id, ...documentSnapshot.data() }))

  return stores
}

/**
 * Find a store by its {@link id}.
 * @param {number} id e.g. 1
 * @return {Promise<object>}
 */
export async function findStoreById (id) {
  const documentReference = doc(storeCollection, `${id}`)
  const documentSnapshot = await getDoc(documentReference)

  if (documentSnapshot.exists()) {
    return { id: documentSnapshot.id, ...documentSnapshot.data() }
  }

  throw new Error(`findStoreById-0001 : Store "${id}" not found.`)
}


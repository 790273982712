/**
 * Adds an uppercase to the first letter of each word in a {@link string}
 * @param string
 * @returns {string}
 */
export function addUpperCaseEachWord(string) {
  return string.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

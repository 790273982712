/**
 * @file Service to handle **Application**.
 */

export const store = {
  namespaced: true,
  state: () => ({
    isLoading: false,
    isMenuOpened: false,
    theme: 'lightLm'
  }),
  mutations: {
    load (state) {
      state.isLoading = true
    },
    loaded (state) {
      state.isLoading = false
    },
    openMenu (state) {
      state.isMenuOpened = true
    },
    closeMenu (state) {
      state.isMenuOpened = false
    },
    changeTheme (state, theme) {
      state.theme = theme
    }
  },
  actions: {
    load ({ commit }) {
      commit('load')
    },
    loaded ({ commit }) {
      commit('loaded')
    },
    openMenu ({ commit }) {
      commit('openMenu')
    },
    closeMenu ({ commit }) {
      commit('closeMenu')
    },
    changeTheme({ commit }, theme) {
      commit('changeTheme', theme)
    }
  }
}
import { createApp } from 'vue'

import './registerFirebase'
import './registerStackdriver'

import App from './App.vue'

import router from './registerRouter'
import store from './registerStore'
import i18n from './registerI18n'
import theming from './registerTheming'

import './registerServiceWorker'
import './registerHotjar'

createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(theming)
  .mount('#app')
<template>
  <div class="fr-application-logo">
    <h6 class="fr-application-logo-primary text-h6">
      {{ t("flash-report_application_title") }}
    </h6>
    <v-slide-x-transition>
      <div
        class="fr-application-logo-secondary ml-1 text-caption text-grey-darken-1"
        v-if="isSecondaryDisplayed"
      >
        <span class="mt-1">{{ t("flash-report_application_by") }}</span>
        <img
          alt="Company logo"
          class="ml-1"
          :src="logo"
          :width="logoWidth"
          :height="logoHeight"
        />
      </div>
    </v-slide-x-transition>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed } from 'vue';
// import { findUserProfile } from '@/user/services/repositories/userProfileRepository'

const { t } = useI18n();
const isSecondaryDisplayed = ref(false);
const logo = ref('');
const logoWidth = ref(0);
const logoHeight = ref(0);
const store = useStore();

const theme = computed(() => store.state.app.theme)


onMounted(() => {
  setTimeout(() => {
    isSecondaryDisplayed.value = true;
    // setBuLogo();
    logo.value = theme.value === 'darkLm' 
        ? require('@/assets/logo_lm_darkmode.png')
        : require('@/assets/logo_lm.png');
      logoWidth.value = "90";
      logoHeight.value = "60";
  }, 1000);

// Watcher pour détecter les changements de thème
store.watch(() => store.state.app.theme, () => {
  // setBuLogo();
  logo.value = theme.value === 'darkLm' 
        ? require('@/assets/logo_lm_darkmode.png')
        : require('@/assets/logo_lm.png');
      logoWidth.value = "90";
      logoHeight.value = "60";
});

//  async function setBuLogo() {
    // const userBuId = await getUserBuId();
    // if (userBuId === 1 || userBuId === 5) {
      
    // }
//   else if (userBuId === '10') {
//     logo.value = require('@/assets/logo_bc.png');
//     logoWidth.value="500";
//     logoHeight.value="300";
// }
    //  else {
    //   logo.value = require('@/assets/logo_adeo.png');
    //   logoWidth.value = "38.7";
    //   logoHeight.value = "20";
    // }

    // async function getUserBuId() {
    //   const { selectedBuId: buIdFromUser } = (await findUserProfile()) || {}
    //   return buIdFromUser;
    // }
})
// })
</script>

<style scoped lang="sass">
.fr-application-logo
  display: flex
  align-items: center

  &-secondary
    display: flex
    align-items: center
</style>

<template>
    <div class="fr-blurr"
         id="fr-blurr"
         :class="{ 'fr-blurr-active': isActive }">
         <section class="fr-blurr-message">
            <h1>{{ t('flash-report_blur_title') }}</h1>
            <p>{{ t('flash-report_blur_description') }}</p>
            <FrButton id="fr-blurr-btn"
                      class="fr-blurr-message-action"
                      @click="forceCloseActivation">
                {{ t('flash-report_blur_button') }}
            </FrButton>
         </section>
    </div>
</template>

<script setup>
import { onMounted, onUnmounted, defineProps, defineEmits, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import FrButton from './FrButton'

const props = defineProps({
    timeout: {
        type: Number,
        default: 30000
    },
    autoRevival: {
        type: Boolean,
        default: true
    }
})

const emits = defineEmits(['blur'])

const { t } = useI18n()

const isActive = ref(false)

let timeoutId = undefined

function toggleActivation () {
    if (timeoutId) {
        window.clearTimeout(timeoutId)
    }

    if (props.autoRevival) {
        isActive.value = false
    }

    timeoutId = window.setTimeout(
        () => {
            isActive.value = true
            emits('blur')
        }, 
        props.timeout)
}

function forceCloseActivation() {
    isActive.value = false
}

onMounted(() => {
    toggleActivation()
    
    window.addEventListener('scroll', () => toggleActivation())
    window.addEventListener('mousemove', () => toggleActivation())
    window.addEventListener('touchmove', () => toggleActivation())
})

onUnmounted(() => {
    window.removeEventListener('scroll', () => toggleActivation())
    window.removeEventListener('mousemove', () => toggleActivation())
    window.removeEventListener('touchmove', () => toggleActivation())
})
</script>

<style lang="sass" scoped>
.fr-blurr
    position: fixed
    top: 0
    bottom: 0
    right: 0
    left: 0
    z-index: -1
    overflow: auto
    
    transition: backdrop-filter 2s ease-in-out
    
    display: flex
    align-items: flex-start
    justify-content: center

    &-active
        z-index: 9999
        overflow: hidden
        backdrop-filter: blur(7px)

    &-message
        padding: 16px
        opacity: 0

        transition: opacity 1s ease-in-out

        &-action
            margin-top: 16px

    &-active &-message
        opacity: 1
</style>
<template>
  <div class="app-navigation pr-4 pl-4"
       id="app-navigation">
    <div class="app-navigation-content">
      <FrChip class="mr-2"
              data-test-id="fr-chip-bu"
              :to="{ name: 'DashboardBu' }">
        {{ t('flash-report_navigation_myBu') }}
      </FrChip>
      <FrChip class="mr-2"
              data-test-id="fr-chip-region"
              :to="{ name: 'DashboardRegion'}">
        {{ t('flash-report_navigation_myRegion') }}        
      </FrChip>
      <FrChip class="mr-2"
              data-test-id="fr-chip-store"
              :to="{ name: 'DashboardStore' }">
        {{ t('flash-report_navigation_myStore') }}
      </FrChip>

      <RegionNavigationItem class="mr-2"
                           :id="bookmarkedRegionId"
                           :key="`bookmarked-region_${bookmarkedRegionId}`"
                           v-for="(_, bookmarkedRegionId) in userBookmarkRegion"
                           @close="removeBookmarkedRegion"/>

      <StoreNavigationItem class="mr-2"
                           :id="bookmarkedStoreId"
                           :key="`bookmarked-store_${bookmarkedStoreId}`"
                           v-for="(_, bookmarkedStoreId) in userBookmarkStore"
                           @close="removeBookmarkedStore" />
      <v-menu>
        <template #activator="{ props }">
          <v-chip color="secondary"
                  variant="outlined"
                  v-bind="props">
            <FrIcon class="mr-2"
                    color="secondary"
                    modelValue="add" />
            {{ t('flash-report_navigation_add') }}
          </v-chip>
        </template>

        <v-list>
          <v-list-item :to="{ name: 'RegionSelect', query: { 'utm_medium': 'navigation-add' } }">
            <template #title>{{ t('flash-report_navigation_add_region') }}</template>
          </v-list-item>
          <v-list-item :to="{ name: 'StoreSelect', query: { 'utm_medium': 'navigation-add' } }">
            <template #title>{{ t('flash-report_nagivation_add_store') }}</template>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { findAllUserBookmarkStoreAsync, deleteUserBookmarkStoreById } from '@/user/services/repositories/userBookmarkStoreRepository'
import { findAllUserBookmarkRegionAsync, deleteUserBookmarkRegionById } from '@/user/services/repositories/userBookmarkRegionRepository'

import FrChip from '@/components/FrChip'
import FrIcon from '@/components/FrIcon'

import RegionNavigationItem from './region/components/RegionNavigationItem'
import StoreNavigationItem from './store/components/StoreNavigationItem'

const { t } = useI18n()

const userBookmarkRegion = ref({})
const userBookmarkStore = ref({})

let userBookmarkRegionSubscriber
let userBookmarkStoreSubscriber

async function getUserBookmarks () {
  if(userBookmarkRegionSubscriber) {
    userBookmarkRegionSubscriber()
  }
  if (userBookmarkStoreSubscriber) {
    userBookmarkStoreSubscriber()
  }
  
  userBookmarkRegionSubscriber = await findAllUserBookmarkRegionAsync(
    (userBookmarks) => {
      userBookmarkRegion.value = userBookmarks
    })

  userBookmarkStoreSubscriber = await findAllUserBookmarkStoreAsync(
    (userBookmarks) => {
      userBookmarkStore.value = userBookmarks
    })
}

async function removeBookmarkedRegion (bookmarkedRegion) {
  await deleteUserBookmarkRegionById(`${bookmarkedRegion.buId}_${bookmarkedRegion.regionId}`)
}

async function removeBookmarkedStore (bookmarkedStore) {
  await deleteUserBookmarkStoreById(`${bookmarkedStore.buId}_${bookmarkedStore.storeId}`)
  window.location.href = '/dashboard/store'
}

onMounted(async () => {
  getUserBookmarks()
})

onUnmounted(() => {
  if(userBookmarkRegionSubscriber) {
    userBookmarkRegionSubscriber()
  }
  if (userBookmarkStoreSubscriber) {
    userBookmarkStoreSubscriber()
  }
})
</script>

<style scoped lang="sass">
.app-navigation
  overflow-x: scroll
  -ms-overflow-style: none
  scrollbar-width: none
  
  &::-webkit-scrollbar
    display: none

  &-content
    display: flex
    align-items: center
    min-width: fit-content
</style>
<template>
  <FrContainer class="dashboard-home"
               id="dashboard-home">
    <div class="pb-2">
      <DashboardComparisonDate :fromDate="updateDateTime"
                               :toDate="comparisonDate"
                               v-if="hasReportMain" />
    </div>
    <v-row v-if="!isTv">
      <v-col cols="12" 
             sm="6"
             md="3">
        <FrTitle data-test-id="main-title" 
                 :isLoading="isLoadingReportMain">
          {{ titleMain }}
        </FrTitle>

        <v-expand-transition>
          <div class="mb-6"
              v-if="hasReportMain">
            <DashboardReportCard class="dashboard-home-card-store"
                                 :modelValue="reportMain"
                                 :isExpanded="isDesktop" />
          </div>
        </v-expand-transition>

        <v-expand-transition>
          <div class="mb-6"
              v-if="!isLoadingReportMain && !hasReportMain">
            <DashboardNoReportNotification />
          </div>
        </v-expand-transition>
      </v-col>
      <v-col cols="12" 
             sm="6"
             md="9">
        <FrTitle data-test-id="departments-title" 
                :isLoading="isLoadingReportsDepartment">
          {{ titleDepartments }}
        </FrTitle>

        <v-expand-transition>
          <v-row v-if="hasAtLeastOneDepartmentReport">
            <v-col cols="12"
                   :key="reportDepartment.id"
                   md="4"
                   lg="3"
                   v-for="reportDepartment in sortedReportsDepartment">
              <DashboardReportCard class="dashboard-home-card-department"
                                   :modelValue="reportDepartment"
                                   :isExpanded="isDesktop || isBookmarked(reportDepartment)">
                <template #actions>
                  <UserBookmarkCheckbox :id="reportDepartment.id"
                                        :modelValue="userBookmarkDepartment[reportDepartment.id]"
                                        @update:modelValue="saveUserBookmarkDepartment" />
                </template>
              </DashboardReportCard>
            </v-col>
          </v-row>
        </v-expand-transition>

        <v-expand-transition>
          <div v-if="!isLoadingReportsDepartment && !hasAtLeastOneDepartmentReport">
            <DashboardNoReportNotification />
          </div>
        </v-expand-transition>
      </v-col>      
    </v-row>

    <v-row v-else>
      <v-col cols="2">
        <DashboardReportCard class="dashboard-home-card-store"
                              :modelValue="reportMain"
                              isExpanded
                              isProminent
                              v-if="hasReportMain" />
        <DashboardNoReportNotification v-else-if="!isLoadingReportMain" />
      </v-col>
      
      <v-col cols="2"
              :key="reportDepartment.id"
              v-for="reportDepartment in sortedReportsDepartment">
        <DashboardReportCard class="dashboard-home-card-department"
                              :modelValue="reportDepartment"
                              isExpanded />
      </v-col>
    </v-row>
  </FrContainer>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useDisplay } from 'vuetify'

import DashboardNoReportNotification from '../components/DashboardNoReportNotification'
import DashboardComparisonDate from '../components/DashboardComparisonDate'
import DashboardReportCard from '../components/DashboardReportCard'

import UserBookmarkCheckbox from '@/user/components/UserBookmarkCheckbox'

import FrContainer from '@/components/FrContainer'
import FrTitle from '@/components/FrTitle'

import { findReportAsync, findAllDepartmentReportsAsync } from '@/dashboard/services/repositories/reportsRepository'

import { findAllUserBookmarkDepartmentAsync, createOrUpdateUserBookmarkDepartment } from '@/user/services/repositories/userBookmarkDepartmentRepository'
import { findBuById } from '@/bu/services/repositories/buRepository'
import { findRegionById } from '@/region/services/repositories/regionRepository'
import { findStoreById } from '@/store/services/repositories/storeRepository'

let reportMainSubscriber
let reportsDepartmentSubscriber

let userBookmarkDepartmentSubscriber

const { t } = useI18n()

const { meta, query, params } = useRoute()

const scope = computed(() => meta.scope)
const buId = computed(() => scope.value === 'bu' ? params.id : params.buId)
const id = computed(() => params.id)

const reportMain = ref(undefined)
const hasReportMain = computed(() => reportMain.value !== undefined)
const reportsDepartment = ref([])
const hasAtLeastOneDepartmentReport = computed(() => reportsDepartment.value.length > 0)
const updateDateTime = computed(() => reportMain.value?.updateTimestamp?.toDate())
const comparisonDate = computed(() => reportMain.value?.comparisonDate?.toDate())

const userBookmarkDepartment = ref({})

const sortedReportsDepartment = computed(() => {
  const bookmarkedReports = reportsDepartment.value.filter(report => userBookmarkDepartment.value[report.id])
  const otherReports = reportsDepartment.value.filter(report => !userBookmarkDepartment.value[report.id])
  return bookmarkedReports.concat(otherReports)
})

const isLoadingUserBookmarkDepartment = ref(true)
const isLoadingReportMain = ref(true)
const isLoadingReportsDepartment = ref(true)

const titleMain = ref(undefined)
const titleDepartments = ref(undefined)

const isDesktop = computed(() => useDisplay().lgAndUp.value)
const isTv = computed(() => isDesktop.value && query.screenMode?.toLowerCase() === 'tv')

let timeoutId

async function computeTitles () {
  if (params.isMine) {
    switch(scope.value) {
      case 'bu':
        titleMain.value = t('flash-report_dashboard_myBu')
        titleDepartments.value = t('flash-report_dashboard_departments')
        break
      case 'region':
        titleMain.value = t('flash-report_dashboard_myRegion')
        titleDepartments.value = t('flash-report_dashboard_departments')
        break
      default:
        titleMain.value = t('flash-report_dashboard_myStore')
        titleDepartments.value = t('flash-report_dashboard_myDepartments')
        break
    }
  } else {
    if (scope.value === 'bu') {
      const buLabel = (await findBuById(buId.value))?.buLabel
      titleMain.value = t('flash-report_dashboard_bu', { value: buLabel })
      titleDepartments.value = t('flash-report_dashboard_departments_of', { value: buLabel })
    }

    if (scope.value === 'region') {
      const regionLabel = (await findRegionById(`${buId.value}_${id.value}`))?.regionLabel
      titleMain.value = t('flash-report_dashboard_region', { value: regionLabel })
      titleDepartments.value = t('flash-report_dashboard_departments_of', { value: regionLabel })
    }

    if (scope.value === 'store') {
      const storeLabel = (await findStoreById(`${buId.value}_${id.value}`))?.storeLabel
      titleMain.value = t('flash-report_dashboard_store', { value: storeLabel })
      titleDepartments.value = t('flash-report_dashboard_departments_of', { value: storeLabel })
    }
  }
}

async function getUserBookmarkDepartment () {
  isLoadingUserBookmarkDepartment.value = true

  try {
    if (userBookmarkDepartmentSubscriber) {
      userBookmarkDepartmentSubscriber()
    }

    userBookmarkDepartmentSubscriber = (await findAllUserBookmarkDepartmentAsync(
      (userBookmarks) => {
        userBookmarkDepartment.value = userBookmarks || {}
      }))
  } finally {
    isLoadingUserBookmarkDepartment.value = false
  }
}

function saveUserBookmarkDepartment (userBookmark) {
  createOrUpdateUserBookmarkDepartment(userBookmark)
}

function isBookmarked(department) {
  return !!userBookmarkDepartment.value[department.id]
}

function getMainReport () {
  isLoadingReportMain.value = true

  if (reportMainSubscriber) {
    reportMainSubscriber()
  }

  reportMainSubscriber = findReportAsync(
    scope.value,
    buId.value,
    id.value,
    (report) => {
      reportMain.value = report
      isLoadingReportMain.value = false
    })
}

function getDepartmentReports () {
  isLoadingReportsDepartment.value = true

  if (reportsDepartmentSubscriber) {
    reportsDepartmentSubscriber()
  }

  reportsDepartmentSubscriber = findAllDepartmentReportsAsync(
    scope.value,
    buId.value,
    id.value,
    (reports) => {
      reportsDepartment.value = reports
      isLoadingReportsDepartment.value = false
    })
}

onMounted(async () => {
  computeTitles()

  getMainReport()
  getDepartmentReports()
  getUserBookmarkDepartment()

  if (!params.isMine) {
    timeoutId = window.setTimeout(() => window.location.href = '/dashboard/store', process.env.VUE_APP_TIMEOUT_BEFORE_REDIRECT_TO_MY_STORE)
  }
})

onUnmounted(() => {
  if (reportMainSubscriber) {
    reportMainSubscriber()
  }

  if (reportsDepartmentSubscriber) {
    reportsDepartmentSubscriber()
  }

  if (userBookmarkDepartmentSubscriber) {
    userBookmarkDepartmentSubscriber()
  }

  if (timeoutId) {
    window.clearTimeout(timeoutId)
  }
})
</script>
<template>
  <v-main class="app-main"
          id="app-main">
    <router-view :key="route.fullPath" />
  </v-main>
</template>

<script setup>
import { useRoute } from 'vue-router'

const route = useRoute()
</script>
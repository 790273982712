import { collection, getFirestore, getDocs, query, where, getDoc, doc, orderBy } from "firebase/firestore";

const regionCollection = collection(getFirestore(), 'regions')

/**
 * Find a region by its associated {@link buId} and its {@link storeId}
 * @param {number} buId e.g. 1
 * @param {number} storeId e.g. 30
 * @returns {Promise<object}
 */
export async function findRegionByStoreId (buId, storeId) {
    const whereClauses = [ where('storeId', 'array-contains', storeId),
                           where('buId', '==', buId) ]
    const q = query(regionCollection, ...whereClauses);
    const querySnapshot = await getDocs(q)

    const region = querySnapshot.docs.reduce((acc, documentSnapshot) => {
      acc.push({ id: documentSnapshot.id, ...documentSnapshot.data() })
      return acc
    }, [])
    
    if (region.length === 1) {
      return region[0]
    }
  
    throw new Error(`findRegionByStoreId-0001 : Region with storeId "${storeId}" not found.`)
}

export async function findAllRegions (buId) {
  const regions = []
  const querySnapshot = await getDocs(query(regionCollection, where('buId', '==', buId), orderBy('regionId')))

  querySnapshot.forEach((documentSnapshot) => regions.push({ id: documentSnapshot.id, ...documentSnapshot.data() }))

  return regions
}

/**
 * Find a region by its {@link id}
 * @param {string} id e.g. 1_1
 * @returns {Promise<object>}
 */
export async function findRegionById (id) {
  const documentReference = doc(regionCollection, id)
  const documentSnapshot = await getDoc(documentReference)

  if (documentSnapshot.exists()) {
    return { id: documentSnapshot.id, ...documentSnapshot.data() }
  }

  throw new Error(`findRegionById-0001 : Region "${id}" not found.`)
}
<template>
  <FrIconButton class="dashboard-record"
                :tooltipLabel="t('flash-report_dashboard_record_new')"
                @click="playConfetti">
    <FrIcon color="amber"
            modelValue="trophy" />
  </FrIconButton>
</template>
<script setup>
import { useI18n } from 'vue-i18n'
import confetti from 'canvas-confetti'

import FrIconButton from '@/components/FrIconButton'
import FrIcon from '@/components/FrIcon'

const { t } = useI18n()

let confettiPointer = undefined

function playConfetti () {
  if (confettiPointer !== undefined) {
    clearInterval(confettiPointer)
  }

  confettiPointer = setInterval(() => 
    confetti({
      particleCount: 180,
      spread: 90,
      origin: {
        x: Math.random(),
        y: Math.random()
      }
    })
    , 1000)

  setTimeout(() => clearInterval(confettiPointer), 5000)
}
</script>
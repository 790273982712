<template>
  <v-card class="fr-card"
          :class="{ 'fr-card-prominent': props.isProminent }"
          :loading="props.isLoading"
          :variant="props.variant"
          @click="emitClick">
    <slot name="title" />
    <v-card-text  class="fr-card-content">
      <slot />
    </v-card-text>
    <v-expand-transition>
      <div v-if="$slots.expand">
        <slot name="expand" />
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false
  },
  isProminent: {
    type: Boolean,
    default: false
  },
  variant: {
    type: String,
    default: 'outlined',
    validator: (value) => ['outlined', 'tonal'].includes(value)
  }
})

const emits = defineEmits(['click'])

function emitClick () {
  emits('click')
}
</script>

<style scoped lang="sass">
.fr-card
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity))

  &-prominent
    border: 2px solid black
</style>
import { collection, doc, getFirestore, onSnapshot, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore'

import { isAuthenticated, getAuthenticatedUser } from '@/authentication/services/authenticationService'

const usersBookmarkRegionCollection = collection(getFirestore(), 'users_bookmark_region')

/**
 * Find all bookmarks for the authenticated user.
 * @param {function} callbackFn
 * @return {Promise<*>}
 */
export async function findAllUserBookmarkRegionAsync (callbackFn) {
  const isAuthenticatedUser = await isAuthenticated()

  if (!isAuthenticatedUser) {
    return undefined
  }

  const id = getAuthenticatedUser().uid

  return onSnapshot(
    doc(usersBookmarkRegionCollection, id),
    documentSnapshot => {
      if (documentSnapshot.exists()) {
        const data = documentSnapshot.data()
        const userBookmarks = {}
        
        Object
          .entries(data)
          .filter(([ key, value ]) => key.match(/^\d*_\d*$/) && value)
          .forEach(([ key, value ]) => userBookmarks[key] = value)
          
        const orderedUserBookmarks = Object
          .keys(userBookmarks)
          .sort((a, b) => {
            const aSplit = a.split('_')
            const bSplit = b.split('_')
            return +aSplit[1] - +bSplit[1]
          })
          .reduce(
            (acc, key) => {
              acc[key] = userBookmarks[key]
              return acc
            }, 
            {}
          )

        callbackFn(orderedUserBookmarks)
      } else {
        callbackFn({})
      }
    })
}

/**
 * Create or update bookmarks via {@link userBookmarkRegion} for the authenticated user.
 * @param {object} userBookmarkRegion
 * @return {Promise<*>}
 */
export async function createOrUpdateUserBookmarkRegion (userBookmarkRegion) {
  const id = getAuthenticatedUser().uid
  const documentReference = doc(usersBookmarkRegionCollection, id)

  const documentSnapshot = await getDoc(documentReference)

  if (documentSnapshot.exists()) {
    return updateDoc(
      documentReference,
      {
        lastModifiedAt: serverTimestamp(),
        ...userBookmarkRegion
      })
  } else {
    return setDoc(
      documentReference,
      {
        createdAt: serverTimestamp(),
        ...userBookmarkRegion
      })
  }
}

/**
 * Delete a specific bookmark via its {@link id}.
 * @param {string} id 
 * @return {Promise<*>}
 */
export async function deleteUserBookmarkRegionById (userBookmarkRegionId) {
  const id = getAuthenticatedUser().uid
  const documentReference = doc(usersBookmarkRegionCollection, id)

  const documentSnapshot = await getDoc(documentReference)

  if (documentSnapshot.exists()) {
    const data = documentSnapshot.data()
    delete data[userBookmarkRegionId]

    return setDoc(
      documentReference,
      {
        lastModifiedAt: serverTimestamp(),
        ...data
      })
  } else {
    throw new Error(`Unable to find the bookmarked region : ${userBookmarkRegionId}`)
  }
}

<template>
  <v-btn class="fr-button"
         :color="props.type"
         variant="outlined"
         @click="emitClick">
    <slot />
  </v-btn>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'primary',
    validator: (value) => ['primary', 'error'].includes(value)
  }
})

const emits = defineEmits(['click'])

function emitClick (event) {
  emits('click', event)
}
</script>
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { getFirestore } from 'firebase/firestore'
import { getPerformance } from 'firebase/performance'
import { getAnalytics } from 'firebase/analytics'

const configuration = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(configuration)

initializeAppCheck(
  app,
  {
    provider: new ReCaptchaEnterpriseProvider(process.env.VUE_APP_FIREBASE_APP_CHECK),
    isTokenAutoRefreshEnabled: true
  }) // https://firebase.google.com/docs/app-check

getFirestore(app) // https://firebase.google.com/docs/firestore
getPerformance(app) // https://firebase.google.com/docs/perf-mon
getAnalytics(app) // https://firebase.google.com/docs/analytics
<template>
  <v-list class="fr-list"
          density="compact">
    <slot />
  </v-list>
</template>

<style scoped lang="sass">
.fr-list
  background-color: transparent
</style>

import { isAuthenticated, logIn } from '../authentication/services/authenticationService'

async function shouldBeAuthenticated (to, from, next) {
  if (await isAuthenticated()) {
    next()
  } else {
    logIn()
    next(false)
  }
}

export default [
  {
    path: '/store',
    component: () => import(/* webpackChunkName: "store" */ './views/StoreLayout.vue'),
    children: [
      {
        path: 'select',
        name: 'StoreSelect',
        meta: {
          title: 'flash-report_store-select_title',
          hasHeader: true,
          hasMenu: true,
        },
        component: () => import(/* webpackChunkName: "store" */ './views/StoreSelect.vue'),
        beforeEnter: shouldBeAuthenticated
      }
    ]
  }
]

import { collection, doc, getFirestore, getDoc } from 'firebase/firestore'

const departmentCollection = collection(getFirestore(), 'departments')

/**
 * Find a department by its {@link buId} and its {@link departmentId}.
 * @param {number} buId e.g. 1
 * @param {number} departmentId e.g. 13
 * @return {Promise<object>}
 */
export async function findDepartment (buId, departmentId) {
  const documentReference = doc(departmentCollection, `${buId}_${departmentId}`)
  const documentSnapshot = await getDoc(documentReference)

  if (documentSnapshot.exists()) {
    return { id: documentSnapshot.id, ...documentSnapshot.data() }
  }

  throw new Error(`findDepartment-0001 : Department "${buId}_${departmentId}" not found.`)
}

if (process.env.NODE_ENV === 'production') {
  const scriptHotjar = document.createElement('script')
  scriptHotjar.setAttribute('async', 'async');
  const contentHotjar = document.createTextNode(`
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${process.env.VUE_APP_HOTJAR_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `)

  scriptHotjar.appendChild(contentHotjar)
  document.head.appendChild(scriptHotjar)
}

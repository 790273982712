<template>
  <div class="authentication-authenticated-user"
       v-if="isAuthenticated">
    {{ t('flash-report_authentication_authenticated-user_title', {value: authenticatedUser.displayName}) }}
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { getAuthenticatedUser } from '@/authentication/services/authenticationService'

const { t } = useI18n()

const authenticatedUser = ref(undefined)
const isAuthenticated = computed(() => authenticatedUser.value !== undefined)

onMounted(() => {
  authenticatedUser.value = getAuthenticatedUser()
})
</script>

import StackdriverErrorReporter from 'stackdriver-errors-js'

const appFile = process.env.NODE_ENV === 'production' ? require('../public/product-info.json') : require('../../product-info.json')

const errorHandler = new StackdriverErrorReporter();
errorHandler.start({
  key: process.env.VUE_APP_STACKDRIVER_API_KEY,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  service: appFile.name,
  version: appFile.version,
  disabled: process.env.NODE_ENV !== 'production'
})
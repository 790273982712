/**
 * @file Service to handle **Date and Time features**.
 */

import { DateTime, Settings } from 'luxon'

Settings.defaultLocale = process.env.VUE_APP_I18N_LOCALE

const DATE_FORMAT = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  weekday: 'short'
}

const DATE_TIME_FORMAT = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  weekday: 'short',
  hour: 'numeric',
  minute: 'numeric'
}

/**
 * Set the locale used by {@link toFormat()}.
 * @param {string} locale - ISO code e.g. en or fr
 */
export function setDateTimeLocale (newLocale) {
  Settings.defaultLocale = newLocale
}

/**
 * Check if {@link value} is a valid date.
 * @param {date|string} value - Date to check
 * @param {string} fromFormat - Applicable only if {@link value} is a string
 * @returns {boolean}
 *
 * @see {@link https://moment.github.io/luxon/#/validity?id=invalid-datetimes}
 */
export function isDateTimeValid (value, fromFormat) {
  if (value === undefined) {
    throw new Error('isDateTimeValid-0001: value is undefined')
  }

  if (typeof value === 'string' && fromFormat === undefined) {
    throw new Error('isDateTimeValid-0002: fromFormat is undefined')
  }

  const dateTime = typeof value === 'string' ? DateTime.fromFormat(value, fromFormat) : DateTime.fromJSDate(value)
  return dateTime.isValid
}

/**
 * Get the current date.
 * @returns {Date}
 */
export function getNow () {
  return DateTime.now().toJSDate()
}

/**
 * Get the previous year of a {@link date}.
 * @param {date} [date=new Date()]
 * @returns {number}
 */
export function getPreviousYear (date = new Date()) {
  return DateTime.fromJSDate(date).minus({ years: 1 }).year
}

/**
 * Format a {@link value} into a human readable and locale date.
 * @param {Object} value
 * @param {Date|String} value.date - Date to format
 * @param {String} [value.fromFormat] - Applicable only if {@link value.date} is a string
 * @param {Object} [options] - Options to customize the formatting
 * @param {Object} [options.toFormat=DateTime.DATE_MED] - Format to use
 * @param {Boolean} [options.isRelative=false] - If true, the date will be formatted as a relative date
 * @param {Boolean} [options.isCalendarRelative=false] - If true, the date will be formatted as a relative calendar date
 * @returns {String}
 *
 * @see {@link https://moment.github.io/luxon/#/formatting?id=tolocalestring-strings-for-humans}
 * @see {@link https://moment.github.io/luxon/#/formatting?id=table-of-tokens}
 */
export function toFormat (value = { date: new Date() }, options = { toFormat: DateTime.DATE_MED, isRelative: false, isRelativeCalendar: false }) {
  if (!isDateTimeValid(value.date)) {
    throw new Error(`toFormat-0001 - Invalid date : ${value.date}`)
  }

  const dateTime = value.fromFormat ? DateTime.fromFormat(value.date, value.fromFormat) : DateTime.fromJSDate(value.date)

  if (options.isRelative) {
    return dateTime.toRelative()
  } else if (options.isRelativeCalendar) {
    return dateTime.toRelativeCalendar()
  }

  if (typeof options.toFormat === 'string') {
    return dateTime.toFormat(options.toFormat)
  }

  return dateTime.toLocaleString(options.toFormat)
}

/**
 * Display a {@link value} in a human readable and locale date.
 * @param {date} value
 * @returns {string}
 */
export function displayDate (value) {
  try {
    return toFormat({ date: value }, { toFormat: DATE_FORMAT })
  } catch (error) {
    console.warn(error.message)
    return '-'
  }
}

/**
 * Display a {@link value} in a human readable and locale date & time.
 * @param {date} value
 * @returns {string}
 */
export function displayDateTime (value) {
  try {
    return toFormat({ date: value }, { toFormat: DATE_TIME_FORMAT })
  } catch (error) {
    console.warn(error.message)
    return '-'
  }
}

/**
 * Display a {@link value} in a human readable and relative date.
 * @param {date} value
 * @returns {string}
 */
export function displayRelativeDate (value) {
  try {
    return toFormat({ date: value }, { isRelative: true })
  } catch (error) {
    console.warn(error.message)
    return '-'
  }
}

/**
 * Display a {@link value} in a human readable and relative date.
 * @param {date} value
 * @returns {string}
 */
export function displayRelativeCalendarDate (value) {
  try {
    return toFormat({ date: value }, { isRelativeCalendar: true })
  } catch (error) {
    console.warn(error.message)
    return '-'
  }
}

/**
 * Display a {@link value} in a human readable and locale hour.
 * @param {date} value
 * @returns {string}
 */
export function displayHour (value, fromFormat, toFormat) {
  try {
    return toFormat({ date: value, fromFormat }, { toFormat })
  } catch (error) {
    console.warn(error.message)
    return '-'
  }
}

/**
 * Display a {@link value} in a human readable and locale day.
 * @param {date} value
 * @returns {string}
 */
export function displayDay (value) {
  try {
    return toFormat({ date: value, fromFormat: 'c' }, { toFormat: 'cccc' })
  } catch (error) {
    console.warn(error.message)
    return '-'
  }
}

<template>
  <v-btn :aria-label="props.ariaLabel"
         class="fr-icon-button"
         :disabled="isDisabled"
         flat
         icon
         :size="props.size"
         @click="emitClick">
    <slot />
    <v-tooltip activator="parent"
               location="start"
               v-if="hasTooltip">
      {{ props.tooltipLabel }}
    </v-tooltip>
  </v-btn>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

const props = defineProps({
  isDisabled: {
    type: Boolean,
    default: false
  },
  ariaLabel: {
    type: String,
    required: true
  },
  tooltipLabel: {
    type: String
  },
  size: {
    type: String,
    default: 'small',
    validator: (value) => ['x-small', 'small', 'large'].includes(value)
  }
})

const emits = defineEmits(['click'])

const hasTooltip = computed(() => props.tooltipLabel)

function emitClick (event) {
  emits('click', event)
}
</script>
import 'vuetify/styles'

import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: { mdi },
  },
  theme: {
    defaultTheme: 'lightLm',
      themes: {
        lightLm: {
          dark: false,
          colors: {
            primary: '#46a610',
            'primary-lighten-1': '#ebf5de',
            'primary-lighten-2': '#c5e39e',
            'primary-lighten-3': '#9ed05f',
            'primary-lighten-4': '#78be20',
            'primary-darken-1': '#188803',
            'primary-darken-2': '#006902',
            'primary-darken-3': '#035010',
            'primary-darken-4': '#023618',
            secondary: '#6a7081',
            'secondary-lighten-1': '#eeeff1',
            'secondary-lighten-2': '#cfd2d8',
            'secondary-lighten-3': '#b3b7c1',
            'secondary-lighten-4': '#8f94a3',
            'secondary-darken-1': '#494f60',
            'secondary-darken-2': '#343b4c',
            'secondary-darken-3': '#242938',
            'secondary-darken-4': '#171b26',
            grey: '#808080',
            'grey-lighten-1': '#e6e6e6',
            'grey-lighten-2': '#cccccc',
            'grey-lighten-3': '#b3b3b3',
            'grey-lighten-4': '#999999',
            'grey-darken-1': '#666666',
            'grey-darken-2': '#4d4d4d',
            'grey-darken-3': '#333333',
            'grey-darken-4': '#191919',
            error: '#ea302d',
            info: '#0b96cc',
            success: '#46a610',
            warning: '#ea7315',
          }
        },
        darkLm: {
          dark: true,
          colors: {
            primary: '#46a610',
            secondary: '#e6e6e6'
            
          }
        },
        // lightAdeo: {
        //   dark: false,
        //   colors: {
        //     primary: '#00919f',
        //     'primary-lighten-1': '#d9f0f3',
        //     'primary-lighten-2': '#91d5db',
        //     'primary-lighten-3': '#48bac4',
        //     'primary-lighten-4': '#009eac',
        //     'primary-darken-1': '#007f8c',
        //     'primary-darken-2': '#006974',
        //     'primary-darken-3': '#004e57',
        //     'primary-darken-4': '#002e33',
        //     secondary: '#605f9d',
        //     'secondary-lighten-1': '#e7e7f0',
        //     'secondary-lighten-2': '#c5c5dc',
        //     'secondary-lighten-3': '#a4a3c7',
        //     'secondary-lighten-4': '#8281b2',
        //     'secondary-darken-1': '#4b4a8c',
        //     'secondary-darken-2': '#393879',
        //     'secondary-darken-3': '#282863',
        //     'secondary-darken-4': '#1a1a4b',
        //     grey: '#8c8b85',
        //     'grey-lighten-1': '#eeedea',
        //     'grey-lighten-2': '#dddcd5',
        //     'grey-lighten-3': '#c2c1ba',
        //     'grey-lighten-4': '#a7a6a0',
        //     'grey-darken-1': '#71706b',
        //     'grey-darken-2': '#555550',
        //     'grey-darken-3': '#3a3936',
        //     'grey-darken-4': '#1e1e1c',
        //     error: '#ea302d',
        //     info: '#0b96cc',
        //     success: '#46a610',
        //     warning: '#ea7315',
        //   }
        // },
        // darkAdeo: {
        //   dark: true,
        //   colors: {
        //     primary: '#03DAC5'
        //   }
        // }
      }
    }
  }
);
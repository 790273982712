import DashboardLayout from '@/dashboard/views/DashboardLayout'
import DashboardHome from '@/dashboard/views/DashboardHome'

import { isAuthenticated, logIn } from '@/authentication/services/authenticationService'
import { findUserProfile } from '@/user/services/repositories/userProfileRepository'
import { findRegionByStoreId } from '@/region/services/repositories/regionRepository'

export default [
  {
    path: '/dashboard',
    component: DashboardLayout,
    children: [
      {
        path: '',
        name: 'DashboardHome',
        redirect: { name: 'DashboardStore' }
      },
      {
        path: 'bu/:id?',
        name: 'DashboardBu',
        meta: {
          title: 'flash-report_dashboard_title',
          hasHeader: true,
          hasMenu: true,
          scope: 'bu'
        },
        props: true,
        component: DashboardHome,
        beforeEnter: async (to, from, next) => {
          const isUserAuthenticated = await isAuthenticated() 

          if (!isUserAuthenticated) {
            logIn()
            next(false)
          } else if (to.params?.id) {
            next()
          } else {
            const { selectedBuId: buIdFromUser } = (await findUserProfile()) || {}

            if (buIdFromUser !== 0) {
              to.params.id = buIdFromUser
              to.params.isMine = true
              next()
            } else {
              next({ name: 'StoreSelect' })
            }
          }
        }   
      },
      {
        path: 'region/:buId?/:id?',
        name: 'DashboardRegion',
        meta: {
          title: 'flash-report_dashboard_title',
          hasHeader: true,
          hasMenu: true,
          scope: 'region'
        },
        props: true,
        component: DashboardHome,
        beforeEnter: async (to, from, next) => {
          const isUserAuthenticated = await isAuthenticated()
          
          if(!isUserAuthenticated) {
            logIn()
            next(false)
          } else if (to.params?.buId && to.params?.id) {
            next()
          } else {
            const { selectedBuId: buIdFromUser, selectedStoreId: storeIdFromUser } = (await findUserProfile()) || {}
            
            if(buIdFromUser !== 0 && storeIdFromUser !== 0) {
              const { regionId } = (await findRegionByStoreId(buIdFromUser, storeIdFromUser))
            
              to.params.buId = buIdFromUser
              to.params.id = regionId
              to.params.isMine = true
              next()
            } else {
              next({ name: 'StoreSelect' })
            }
          }
        }
      },
      {
        path: 'store/:buId?/:id?',
        name: 'DashboardStore',
        meta: {
          title: 'flash-report_dashboard_title',
          hasHeader: true,
          hasMenu: true,
          scope: 'store'
        },
        props: true,
        component: DashboardHome,
        beforeEnter: async (to, from, next) => {
          const isUserAuthenticated = await isAuthenticated() 

          if (!isUserAuthenticated) {
            logIn()
            next(false)
          } else if (to.params?.buId && to.params?.id) {
            next()
          } else {
            const { selectedBuId: buIdFromUser, selectedStoreId: storeIdFromUser } = (await findUserProfile()) || {}

            if (buIdFromUser !== 0 && storeIdFromUser !== 0) {
              to.params.buId = buIdFromUser
              to.params.id = storeIdFromUser
              to.params.isMine = true
              next()
            } else {
              next({ name: 'StoreSelect' })
            }
          }
        }      
      }
    ]
  }
]

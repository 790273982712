<template>
  <v-app-bar app
             class="app-header"
             id="app-header">
    <v-app-bar-nav-icon aria-label="menu"
                        class="app-header-menu-button"
                        @click.stop="openMenu"
                        v-if="!isTv" />
    <v-toolbar-title>
      <FrApplicationLogo />
    </v-toolbar-title>
    <template #extension
              v-if="!isTv">
      <AppNavigation />
    </template>
  </v-app-bar>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import AppNavigation from './AppNavigation'

import FrApplicationLogo from '@/components/FrApplicationLogo'

const store = useStore()
const { query } = useRoute()

const isTv = computed(() => query.screenMode?.toLowerCase() === 'tv')

function openMenu () {
  store.dispatch('app/openMenu')
}
</script>
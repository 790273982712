<template>
  <FrIconButton ariaLabel="bookmark"
                class="user-bookmark-department-checkbox"
                :id="`user-bookmark-department-checkbox-${id}`"
                @click="toggleBookmark">
      <FrIcon :color="isChecked ? 'amber' : undefined"
              :modelValue="isChecked ? 'star' : 'starOutline'" />
  </FrIconButton>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

import FrIconButton from '@/components/FrIconButton'
import FrIcon from '@/components/FrIcon'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  modelValue: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['update:modelValue'])

const isChecked = computed(() => props.modelValue)

function toggleBookmark () {
  const data = {}
  data[props.id] = !isChecked.value
  emits('update:modelValue', data)
}
</script>

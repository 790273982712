<template>
  <v-chip class="fr-chip"
          :closable="props.isClosable"
          :color="color"
          :variant="variant"
          :label="props.isKpi"
          :to="props.to"
          @click:close.prevent="emitClose">
    <slot />
  </v-chip>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useRoute } from 'vue-router' 

const props = defineProps({
  isKpi: {
    type: Boolean,
    default: false
  },
  isClosable: {
    type: Boolean,
    default: false
  },
  to: {
    type: Object
  },
  color: {
    type: String,
    default: 'primary',
    validator: (value) => ['primary', 'secondary'].includes(value)
  },
})

const emits = defineEmits(['close'])

const variant = computed(() => (props.isKpi || isCurrentRoute()) ? undefined : 'outlined')

function isCurrentRoute () {
  const { name: currentRouteName, params: currentRouteParams } = useRoute()
  const { name: routeName, params: routeParams } = props.to

  let currentRoute = `${currentRouteName}_undefined`

  if (!currentRouteParams.isMine) {
    currentRoute = `${currentRouteName}_${JSON.stringify(currentRouteParams)}`
  }

  const route = `${routeName}_${JSON.stringify(routeParams)}`

  return currentRoute === route
}

function emitClose () {
  emits('close')
}
</script>
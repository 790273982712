<template>
    <v-select class="theme-select"
              density="compact"
              hide-details
              :items="themes"
              :modelValue="props.modelValue"
              variant="outlined"
              @update:modelValue="emitModelValue">

        <template #selection="{ item }">
            <span class="d-flex align center">
                <FrIcon :modelValue="item.raw.icon" />
            </span>
        </template>

        <template #item="{ item, props }">
            <v-list-item v-bind="props">
                <template #prepend>
                    <FrIcon :modelValue="item.raw.icon" />       
                </template>
                <template #title>
                    {{ item.title }}
                </template>
            </v-list-item>
        </template>
    </v-select>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
// import { findUserProfile } from '@/user/services/repositories/userProfileRepository'
import FrIcon from './FrIcon'


// const bu = ref("")
// async function getUserBuId() {
//     const { selectedBuId: buIdFromUser } = (await findUserProfile()) || {}
//     if(buIdFromUser === 1){
//         bu.value = 'Lm'
//     }else{
//         bu.value = 'Adeo'
//     }
// }
// getUserBuId()

const props = defineProps({
    modelValue: {
        type: String,
        default: 'auto'
    }
})

const { t } = useI18n()

const themes = ref([{ title: t('flash-report_light-mode'), value:'lightLm', icon: 'lightMode' },{ title: t('flash-report_dark-mode'), value:'darkLm', icon: 'darkMode' },{ title: t('flash-report_auto-mode'), value: 'auto', icon: 'auto' }])

const emits = defineEmits(['update:modelValue', 'update:theme'])

const browserDarkMode = window.matchMedia("(prefers-color-scheme:dark)")

function emitModelValue (modelValue) {
    emits('update:modelValue', modelValue)
    emitTheme(modelValue)
}

function emitTheme (theme) {
    if(theme === 'auto') {
        browserDarkMode.addEventListener("change", emitBrowserTheme, true)
        emitBrowserTheme()
    } else {
        browserDarkMode.removeEventListener("change", emitBrowserTheme, true)
        emits('update:theme', theme)
    }
}

function emitBrowserTheme() {
    return browserDarkMode.matches ? emits('update:theme', 'darkLm'): emits('update:theme', 'lightLm')
}

watch(() => props.modelValue, () => emitTheme(props.modelValue))

onMounted(() => {
    emitTheme(props.modelValue)
})
</script>
<template>
    <FrNotification class="dashboard-no-report-notification"
                    id="dashboard-no-report-notification"
                    :title="t('flash-report_dashboard_noReport_title')">
        {{ t('flash-report_dashboard_noReport_description') }}
    </FrNotification>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import FrNotification from '@/components/FrNotification'

const { t } = useI18n()
</script>
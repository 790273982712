<template>
  <v-app class="app"
         :theme="theme">
    <FrBlurr :timeout="timeoutBeforeBlurr"
             v-if="!isTv" />
    <FrLoader class="app-loader"
              v-if="isLoading" />
    <AppMenu v-if="hasMenu" />
    <AppHeader v-if="hasHeader" />
    <AppMain />
  </v-app>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import FrLoader from '@/components/FrLoader'
import FrBlurr from '@/components/FrBlurr'

import AppMenu from './AppMenu'
import AppHeader from './AppHeader'
import AppMain from './AppMain'

const store = useStore()
const route = useRoute()

const isLoading = computed(() => store.state.app.isLoading)
const isTv = computed(() => route.query.screenMode?.toLowerCase() === 'tv')
const hasHeader = computed(() => route.meta.hasHeader)
const hasMenu = computed(() => route.meta.hasMenu)
const theme = computed(() => store.state.app.theme)

const timeoutBeforeBlurr = computed(() => +process.env.VUE_APP_TIMEOUT_BEFORE_BLURR)
</script>

<style scoped lang="sass">
.app
  &-loader
    z-index: 1007
</style>

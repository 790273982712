<template>
  <v-progress-linear :aria-label="props.ariaLabel"
                     class="fr-progress-bar"
                     color="primary"
                     height="25"
                     :modelValue="value"
                     rounded>
      <template #default="{ value }">
        <strong>{{ displayPercent(value / 100) }}</strong>
      </template>
  </v-progress-linear>
</template>

<script setup>
import { defineProps, computed } from 'vue'

import { displayPercent } from '@/services/numberService'

const props = defineProps({
  ariaLabel: {
    type: String,
    required: true
  },
  modelValue: {
    type: Number,
    default: 0
  }
})

const value = computed(() => props.modelValue * 100)
</script>
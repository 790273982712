/**
 * @file Service to handle **Number**.
 */
let locale = process.env.VUE_APP_I18N_LOCALE

/**
 * Set the locale used by {@link toFormat()}.
 * @param {string} locale - ISO code e.g. en-US or fr-FR
 */
export function setNumberLocale (newLocale) {
  locale = newLocale
}

/**
 * Check if {@link value} is a valid number.
 * @param {number} value
 * @returns {boolean}
 */
export function isNumberValid (value) {
  return !Number.isNaN(value)
}

/**
 * Format a {@link value} into a human readable and locale ({@link language}) number.
 * @param {number} value - Number to format
 * @param {object} [options] - Options to customize the formatting
 * @param {string} [options.style=decimal] - Style, can be `decimal`, `percent` or `currency`
 * @param {string} [options.currency] - Applicable and mandatory only with style `currency`
 * @param {number} [options.minimumFractionDigits=0] - Minimum number of fraction digits
 * @param {number} [options.maximumFractionDigits=2] - Maximum number of fraction digits
 * @returns {string}
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat}
 *
 * @example <caption>const number = 123456.789</caption>
 * // returns 123 456,79
 * toFormat(number)
 *
 * @example <caption>const number = 123456.789</caption>
 * // returns 123 456,79 €
 * toFormat(number, { style: 'currency', currency: 'EUR' })
 */
export function toFormat (value, options = { style: 'decimal', notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 2 }) {
  const number = Number(value)

  if (!isNumberValid(number)) {
    throw new Error(`toFormat-0001 - Invalid number : ${value}`)
  }

  return new Intl.NumberFormat(locale, options).format(number)
}

/**
 * Display a {@link value} in a human readable and locale number.
 * @param {number} value
 * @param {string} [defaultString='N/A']
 * @returns {string}
 */
export function displayNumber (value, defaultString = 'N/A') {
  if (value === undefined) {
    return defaultString
  }

  try {
    return toFormat(
      value,
      {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })
  } catch (error) {
    console.warn(error.message)
    return defaultString
  }
}

/**
 * Display a {@link value} in a human readable and locale percent.
 * @param {number} value
 * @param {string} [defaultString='N/A']
 * @returns {string}
 */
export function displayPercent (value, defaultString = 'N/A') {
  if (value === undefined) {
    return defaultString
  }

  try {
    return toFormat(
      value,
      {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
  } catch (error) {
    console.warn(error.message)
    return defaultString
  }
}

/**
 * Display a {@link value} in a human readable and locale currency.
 * @param {number} value
 * @param {string} [defaultString='N/A']
 * @returns {string}
 */
export function displayCurrency (value, defaultString = 'N/A') {
  if (value === undefined) {
    return defaultString
  }

  try {
    return toFormat(
      value,
      {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
  } catch (error) {
    console.warn(error.message)
    return defaultString
  }
}

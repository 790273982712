/**
 * @file Service to handle **authentication features**.
 *
 * @see {@link https://firebase.google.com/docs/auth}
 */
import { getApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInWithCustomToken, signOut, updateProfile, updateEmail, getIdToken } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'

/**
 * Find token from Ping Federated for a {@link code}.
 * @private
 * @param {string} code
 * @return {Promise<*>}
 */
function getToken (code) {
  return httpsCallable(getFunctions(getApp(), 'europe-west1'), 'fr_getToken')({ code })
}

/**
 * Update some information about the authenticated user.
 * @private
 * @param {object} profile
 * @param {string} profile.displayName
 * @return {Promise<*>}
 */
function updateAuthenticatedProfile (profile) {
  return updateProfile(getAuth().currentUser, profile)
}

/**
 * Update the {@link email} of the authenticated user.
 * @private
 * @param {string} email
 * @return {Promise<*>}
 */
function updateAuthenticatedEmail (email) {
  return updateEmail(getAuth().currentUser, email)
}

/**
 * Check if the user is authenticated.
 * @returns {Promise<boolean>}
 */
export function isAuthenticated () {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(
      getAuth(),
      user => resolve(!!user),
      error => reject(error))
  })
}

/**
 * Get authenticated user.
 * @returns {Firebase.User}
 */
export function getAuthenticatedUser () {
  return getAuth().currentUser
}

/**
 * Redirect to Ping Federated login view.
 */
export function logIn () {
  const url = new URL(process.env.VUE_APP_PINGONE_AUTH_URI)
  url.searchParams.set('response_type', 'code')
  url.searchParams.set('client_id', process.env.VUE_APP_PINGONE_CLIENT_ID)
  url.searchParams.set('scope', 'openid profile email advprofile')
  url.searchParams.set('redirect_uri', `${location.origin}${process.env.VUE_APP_PINGONE_REDIRECT_URI}`)

  location.href = url.href
}

/**
 * Finish "Log in" process between Ping Federated and Firebase Authentication.
 * @params {string} code
 * @returns {Promise<UserCredential>}
 *
 * @see {@link https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithcustomtoken}
 */
export async function finishLogIn (code) {
  try {
    const { token, displayName, email } = (await getToken(code)).data
    await signInWithCustomToken(getAuth(), token)
    await updateAuthenticatedProfile({ displayName })
    await updateAuthenticatedEmail(email)
    await getIdToken(getAuth().currentUser, true)
  } catch (error) {
    throw new Error(`finishLogIn-0001: Failed to finish "Log in" process due to "${error.code}-${error.message}"`)
  }
}

/**
 * Log out the current authenticated user.
 * @returns {Promise<void>}
 *
 * @see {@link https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signout}
 */
export function logOut () {
  try {
    return signOut(getAuth())
  } catch (error) {
    throw new Error(`logOut-0001: Failed to log out due to "${error.code}-${error.message}"`)
  }
}

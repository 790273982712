<template>
  <v-slide-x-transition>
    <div v-if="!isLoading">
      <FrChip class="store-navigation-item"
              isClosable
              :to="{ name: 'DashboardStore', params: { buId: `${store.buId}`, id: `${store.storeId}` } }"
              @close="emitClose">
        <strong>{{ t('flash-report_navigation_store_prepend') }} - #{{ store.storeId }}</strong>
        &nbsp;
        {{ store.storeLabel }}
      </FrChip>
    </div>
  </v-slide-x-transition>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref , computed} from 'vue'
import { useI18n } from 'vue-i18n'

import { findStoreById } from '../services/repositories/storeRepository'

import FrChip from '@/components/FrChip'

const { t } = useI18n()

const props = defineProps({
  id: {
    type: String,
    required: true
  }
})

const emits = defineEmits(['close'])

const store = ref(undefined)
const isLoading = computed(() => store.value === undefined)

async function getStore () {
  store.value = await findStoreById(props.id)
}

function emitClose () {
  emits('close', store.value)
}

onMounted(() => {
  getStore()
})
</script>
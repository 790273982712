<template>
  <div class="dashboard-layout"
       id="dashboard-layout">
    <router-view :key="route.fullPath" />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'

const route = useRoute()
</script>

import { collection, getFirestore, getDocs, getDoc, doc, query, orderBy, where } from 'firebase/firestore'

const buCollection = collection(getFirestore(), 'bus')

/**
 * Find all BUs with IDs 1, 5, and 10.
 * @return {Promise<Array>}
 */
export async function findAllBUs () {
  const bus = []
  const querySnapshot = await getDocs(query(buCollection, orderBy('buId'), where('buId', 'in', [1, 5, 10])))

  querySnapshot.forEach(documentSnapshot => bus.push({ id: documentSnapshot.id, ...documentSnapshot.data() }))

  return bus
}

/**
 * Find a BU by its {@link id}.
 * @param {number} id e.g. 1
 * @return {Promise<object>}
 */
export async function findBuById (id) {
  const documentReference = doc(buCollection, `${id}`)
  const documentSnapshot = await getDoc(documentReference)

  if (documentSnapshot.exists()) {
    return { id: documentSnapshot.id, ...documentSnapshot.data() }
  }

  throw new Error(`findBuById-0001 : BU "${id}" not found.`)
}

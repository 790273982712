<template>
    <v-slide-x-transition>
      <div v-if="!isLoading">
        <FrChip class="region-navigation-item"
                isClosable
                :to="{ name: 'DashboardRegion', params: { buId: `${region.buId}`, id: `${region.regionId}` } }"
                @close="emitClose">
          <strong>{{ t('flash-report_navigation_region_prepend') }} - #{{ region.regionId }}</strong>
          &nbsp;
          {{ region.regionLabel }}
        </FrChip>
      </div>
    </v-slide-x-transition>
  </template>
  
  <script setup>
  import { defineProps, defineEmits, onMounted, ref , computed} from 'vue'
  import { useI18n } from 'vue-i18n'
  
  import { findRegionById } from '../services/repositories/regionRepository'
  
  import FrChip from '@/components/FrChip'

  const { t } = useI18n()
  
  const props = defineProps({
    id: {
      type: String,
      required: true
    }
  })
  
  const emits = defineEmits(['close'])
  
  const region = ref(undefined)
  const isLoading = computed(() => region.value === undefined)
  
  async function getRegion () {
    region.value = await findRegionById(props.id)
  }
  
  function emitClose () {
    emits('close', region.value)
  }
  
  onMounted(() => {
    getRegion()
  })
  </script>
<template>
  <v-icon class="fr-icon"
          :color="props.color"
          :icon="icons[props.modelValue]" />
</template>

<script setup>
import { defineProps } from 'vue'
import { mdiStar, mdiStarOutline, mdiTrophyOutline, mdiClose, mdiInformationOutline, mdiMagnify, mdiBackspaceOutline, mdiPlusCircleOutline, mdiCloseCircleOutline, mdiBugOutline, mdiWhiteBalanceSunny, mdiWeatherNight, mdiTuneVertical } from '@mdi/js'

const props = defineProps({
  color: {
    type: String,
    default: 'grey',
    validator: (value) => ['primary', 'secondary', 'grey', 'amber'].includes(value)
  },
  modelValue: {
    type: String,
    required: true
  }
})

const icons = {
  star: mdiStar,
  starOutline: mdiStarOutline,
  trophy: mdiTrophyOutline,
  close: mdiClose,
  info: mdiInformationOutline,
  search: mdiMagnify,
  clear: mdiBackspaceOutline,
  add: mdiPlusCircleOutline,
  remove: mdiCloseCircleOutline,
  lightMode: mdiWhiteBalanceSunny,
  darkMode: mdiWeatherNight,
  auto: mdiTuneVertical,
  bug: mdiBugOutline
}
</script>

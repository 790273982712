import { createRouter, createWebHistory } from 'vue-router'

import i18n from './registerI18n'

import storeRoutes from './store/storeRoutes'
import dashboardRoutes from './dashboard/dashboardRoutes'
import authenticationRoutes from './authentication/authenticationRoutes'

import { findUserProfile } from './user/services/repositories/userProfileRepository'
import { setNumberLocale } from './services/numberService'
import { setDateTimeLocale } from './services/dateTimeService'
import regionRoutes from './region/regionRoutes'


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Default',
      redirect: { name: 'DashboardHome' }
    },
    ...regionRoutes,
    ...storeRoutes,
    ...dashboardRoutes,
    ...authenticationRoutes
  ]
})

router.beforeEach(async (to, _, next) => {
  const availableLanguageCodes = process.env.VUE_APP_I18N_AVAILABLE_LOCALES.split(',')
  const languageCodeFromUser = (await findUserProfile())?.preferredLanguageCode
  const languageCode = availableLanguageCodes.find(languageCode => languageCode === languageCodeFromUser) || process.env.VUE_APP_I18N_FALLBACK_LOCALE

  i18n.global.locale.value = languageCode
  setNumberLocale(languageCode)
  setDateTimeLocale(languageCode)

  const applicationTitle = i18n.global.t('flash-report_application_title')
  const applicationCompany = i18n.global.t('flash-report_application_company')
  const viewTitle = i18n.global.t(to.meta.title)
  document.title = `${viewTitle} - ${applicationTitle} - ${applicationCompany}`
  next()
})

export default router

<template>
    <FrCard class="dashboard-report-card"
            :id="`dashboard-report-card_${props.modelValue.id}`"
            :isLoading="isLoading"
            :isProminent="props.isProminent"
            @click="emitExpand">
        <template #title>
            <v-list-item density="compact"
                         lines="two">
                <template #title>
                    {{ title }}
                </template>
                <template #subtitle>
                    #{{ id }}
                    <span v-if="hasRanking">
                        &nbsp;-&nbsp;
                        <span v-html="t('flash-report_ranking', { value: props.modelValue.ranking, count: props.modelValue.ranking})" />
                    </span>
                </template>
                <template #append>
                    <DashboardRecord v-if="props.modelValue.isRecord" />
                    <slot name="actions" />
                </template>
            </v-list-item>
        </template>

        <p class="dashboard-report-card-current">
            <span>
                <span class="text-grey-darken-1">{{ t('flash-report_dashboard_turnoverSummary_currentTurnover') }}</span>
                &nbsp;
                <strong>{{ displayCurrency(props.modelValue.currentTurnover) }}</strong>
            </span>
            <strong>{{ displayCurrency(props.modelValue.target) }}</strong>
        </p>

        <FrProgressBar ariaLabel="target progression"
                       :modelValue="targetProgression" />

        <p class="dashboard-report-card-previous mt-4 text-grey-darken-1">
            {{ t('flash-report_dashboard_turnoverSummary_previousTurnover', { year: previousYear }) }}
            &nbsp;
            <strong>{{ displayCurrency(props.modelValue.previousTurnover) }}</strong>
        </p>

        <template #expand
                  v-if="isExpanded">
            <FrDivider />
            <FrList>
                <FrListItem>
                    <template #title>{{ t('flash-report_dashboard_orderSummary_pending') }}</template>
                    <template #value>
                        <FrChip isKpi>{{ displayCurrency(props.modelValue.customerValidatedOrder) }}</FrChip>
                    </template>
                </FrListItem>
                <FrListItem>
                    <template #title>{{ t('flash-report_dashboard_orderSummary_paid') }}</template>
                    <template #value>
                        <FrChip isKpi>{{ displayCurrency(props.modelValue.customerClosedOrder) }}</FrChip>
                    </template>
                </FrListItem>
                <FrListItem>
                    <template #title>{{ t('flash-report_dashboard_orderSummary_returned') }}</template>
                    <template #value>
                        <FrChip isKpi>{{ displayCurrency(props.modelValue.returnedOrder) }}</FrChip>
                    </template>
                </FrListItem>
            </FrList>
        </template>
    </FrCard>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { displayCurrency } from '@/services/numberService'
import { getPreviousYear } from '@/services/dateTimeService'
import { addUpperCaseEachWord } from '@/services/stringService'

import { findDepartment } from '@/department/services/repositories/departmentRepository'

import { findStoreById } from '@/store/services/repositories/storeRepository'

import { findBuById } from '@/bu/services/repositories/buRepository'

import { findRegionById } from '@/region/services/repositories/regionRepository'

import FrCard from '@/components/FrCard'
import FrProgressBar from '@/components/FrProgressBar'
import FrList from '@/components/FrList'
import FrListItem from '@/components/FrListItem'
import FrChip from '@/components/FrChip'
import FrDivider from '@/components/FrDivider'

import DashboardRecord from './DashboardRecord'

const { t } = useI18n()

const props = defineProps({
    isExpanded: {
        type: Boolean,
        default: false
    },
    isProminent: {
        type: Boolean,
        default: false
    },
    modelValue: {
        type: Object,
        required: true
    }
})

const emits = defineEmits(['expand'])

const id = ref(undefined)
const title = ref(undefined)

const isLoading = computed(() => title.value === undefined)
const isManuallyExpanded = ref(false)
const isExpanded = computed(() => props.isExpanded || isManuallyExpanded.value)

const previousYear = computed(() => getPreviousYear(props.modelValue.updateTimestamp.toDate()))

const hasRanking = computed(() => props.modelValue.ranking !== undefined)

const targetProgression = computed(() => {
    if (props.modelValue.currentTurnover === undefined ||
        props.modelValue.target === undefined ||
        props.modelValue.target === 0) {
        return undefined
    }

    return props.modelValue.currentTurnover / props.modelValue.target
})

async function computeTitle() {
    if (props.modelValue.departmentId) {
        id.value = props.modelValue.departmentId
        title.value = (await findDepartment(props.modelValue.buId, props.modelValue.departmentId))?.departmentLabel
        //We add accents to departments for LMFR
        if (props.modelValue.buId === 1) {
          title.value = computeDepartmentName(title.value, props.modelValue.buId)
        }
        return
    }

    if (props.modelValue.regionId) {
        id.value = props.modelValue.regionId
        title.value = addUpperCaseEachWord((await findRegionById(`${props.modelValue.buId}_${props.modelValue.regionId}`))?.regionLabel)
        return
    }

    if (props.modelValue.storeId) {
        id.value = props.modelValue.storeId
      title.value = addUpperCaseEachWord((await findStoreById(`${props.modelValue.buId}_${props.modelValue.storeId}`))?.storeLabel)
        return
    }

    id.value = props.modelValue.buId
    title.value = addUpperCaseEachWord((await findBuById(props.modelValue.buId))?.buLabel)
}

function computeDepartmentName(department, bu) {
  if (bu !== 1) return department;
  switch (department) {
    case "Materiaux de construction": return t('flash-report_department_building-materials')
    case "Menuiserie": return t('flash-report_department_carpentry')
    case "Electricite-plomberie": return t('flash-report_department_electricity-plumbing')
    case "Outillage": return t('flash-report_department_tools')
    case "Rangement cuisine": return t('flash-report_department_kitchen-storage')
    case "Sol et carrelage mural": return t('flash-report_department_floor-wall-tiles')
    case "Sanitaire": return t('flash-report_department_sanitary')
    case "Confort & energie renouvelable": return t('flash-report_department_comfort-renewable-energy')
    case "Jardin": return t('flash-report_department_garden')
    case "Quincaillerie": return t('flash-report_department_hardware')
    case "Peinture": return t('flash-report_department_paint')
    case "Decoration": return t('flash-report_department_decoration')
    case "Eclairage": return t('flash-report_department_lighting')
  }
}

function emitExpand() {
    isManuallyExpanded.value = !isManuallyExpanded.value
    emits('expand', isManuallyExpanded.value)
}

onMounted(async () => {
    computeTitle()
})
</script>

<style scoped lang="sass">
.dashboard-report-card
    &-current
        display: flex
        align-items: center
        justify-content: space-between
</style>
